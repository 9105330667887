export enum CONTENT_TYPE {
    START = "content-page-start",
    ARTICLE_DEFAULT = "content-page-article",
    ARTICLE_PROFESSIONAL = "content-page-article-professional",
    ARTICLE_PUBLIC = "content-page-article-public",
    LIST = "content-page-list",
    CONTENT_CARD = "content-block-card-contentcard",
    UPDATES_CARD = "content-block-card-updatescard",
    ARTICLE_UPDATES_CARD = "content-block-card-articleupdatescard"
}

export function getContentType(types?: string[]): CONTENT_TYPE {
    if (!types || types.length <= 0) {
        return CONTENT_TYPE.ARTICLE_DEFAULT
    }

    const type = types.join("-").toLowerCase()
    if (type.startsWith(CONTENT_TYPE.START)) {
        return CONTENT_TYPE.START
    }

    if (type.startsWith(CONTENT_TYPE.LIST)) {
        return CONTENT_TYPE.LIST
    }

    if (type.startsWith(CONTENT_TYPE.ARTICLE_PROFESSIONAL)) {
        return CONTENT_TYPE.ARTICLE_PROFESSIONAL
    }

    if (type.startsWith(CONTENT_TYPE.ARTICLE_PUBLIC)) {
        return CONTENT_TYPE.ARTICLE_PUBLIC
    }

    if (type.startsWith(CONTENT_TYPE.CONTENT_CARD)) {
        return CONTENT_TYPE.CONTENT_CARD
    }

    if (type.startsWith(CONTENT_TYPE.UPDATES_CARD)) {
        return CONTENT_TYPE.UPDATES_CARD
    }

    if (type.startsWith(CONTENT_TYPE.ARTICLE_UPDATES_CARD)) {
        return CONTENT_TYPE.ARTICLE_UPDATES_CARD
    }

    return CONTENT_TYPE.ARTICLE_DEFAULT
}
