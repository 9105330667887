import { Node } from "components/Drawer/NodeDrawer";
import { Icon } from "components/Icon";
import NavigationSubsection from "components/Layout/Aside/NavigationSubSection";
import Link from "components/Link";
import { t } from "locales";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
export default function NavigationSection(props: Node) {
  const {
    name,
    url,
    active,
    nodes,
    shortcut,
    icon,
    disabled
  } = props;

  // Handle semi-controlled open state of subsections
  const [_open, setOpen] = useState<boolean | undefined>(undefined);
  const handleToggleExpanded = (e: any) => {
    e.stopPropagation();
    setOpen(prev => typeof prev === "undefined" || !prev);
  };
  useEffect(() => {
    setOpen(active);
  }, [active]);
  const open = _open ?? active ?? false;
  return <StyledListItem disableGutters data-sentry-element="StyledListItem" data-sentry-component="NavigationSection" data-sentry-source-file="NavigationSection.tsx">
            <StyledLink expanded={Boolean(open || active).toString()} title={name} button disabled={disabled} fullWidth href={shortcut ?? url} startIcon={icon && <Icon name={icon} />} data-sentry-element="StyledLink" data-sentry-source-file="NavigationSection.tsx">
                <Typography noWrap data-sentry-element="Typography" data-sentry-source-file="NavigationSection.tsx">{name}</Typography>
            </StyledLink>
            {nodes ? <>
                    <ExpandButton expanded={open.toString()} aria-label={open ? t["open-section"]["true"] : t["open-section"]["false"]} onClick={handleToggleExpanded}>
                        {open ? <Icon name="expandLess" color="action" /> : <Icon name="expandMore" color="action" />}
                    </ExpandButton>
                    <NavigationSubsection nodes={nodes} open={open} />
                </> : null}
        </StyledListItem>;
}
const StyledListItem = styled(ListItem)(() => ({
  tion: "background-color .2s",
  padding: 0,
  position: "relative",
  zIndex: 1,
  display: "flex",
  flexWrap: "wrap"
}));
const StyledLink = styled(Link)<{
  expanded: string;
}>(({
  expanded,
  theme
}) => ({
  flex: 1,
  borderRadius: 0,
  justifyContent: "flex-start",
  minWidth: 48,
  height: 48,
  padding: theme.spacing(1, 1.25),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ...(expanded === "true" && {
    backgroundColor: theme.palette.background.default
  }),
  "&:focus": {
    boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.main}`
  }
}));
const ExpandButton = styled(Button)<{
  expanded: string;
}>(({
  theme,
  expanded
}) => ({
  backgroundColor: expanded === "true" ? theme.palette.background.default : "transparent",
  borderRadius: 0,
  justifyContent: "flex-start",
  minWidth: 48,
  height: 48,
  padding: theme.spacing(1, 1.25),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "&:focus": {
    boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.main}`
  }
}));