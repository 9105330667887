import { ICrumb } from "components/Breadcrumb/Breadcrumb";
import { general } from "config/config";
import Head from "next/head";
export default function BreadcrumbHead({
  crumbs
}: {
  crumbs: ICrumb[];
}) {
  return <Head data-sentry-element="Head" data-sentry-component="BreadcrumbHead" data-sentry-source-file="BreadcrumbHead.tsx">
            <script dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: crumbs.map((crumb, index) => ({
          "@type": "ListItem",
          item: `${general.appHost}${crumb.url}`,
          name: crumb.name,
          position: index + 1
        }))
      })
    }} type="application/ld+json" />
        </Head>;
}