import NodeDrawer, { Node } from "components/Drawer/NodeDrawer";
import Navigation from "components/Layout/Aside/Navigation";
import { useInteractions } from "components/interactions/InteractionsContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export interface AsideProps {
  node: Node;
  activeElement?: string;
}
export default function Aside({
  node
}: AsideProps) {
  const theme = useTheme();
  const {
    hasNav,
    showNav,
    closeNav
  } = useInteractions();
  const showAsideNavigation = useMediaQuery(theme.breakpoints.up("md"));
  if (!hasNav) {
    return null;
  }
  if (showAsideNavigation) {
    return <Navigation node={node} />;
  }
  return <NodeDrawer node={node} onClose={closeNav} open={showNav} data-sentry-element="NodeDrawer" data-sentry-component="Aside" data-sentry-source-file="index.tsx" />;
}